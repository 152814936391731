<template>
  <div class="home">
    <Header></Header>
     <div class="news-main-box">

			<div class="product01-box" v-for="(item,index) in allMsg" :key="index">
        <div class="bottom-two-nav">
						<p class="p1" @click="goAllMsg">最新消息</p>
						<p class="shu" v-if="twoNav==1">|</p>
						<p class="p2" @click="goYearsUpdate" v-if="twoNav==1">更新记录</p>
            <p class="shu">|</p>
            <p class="p3">{{item.name}}</p>
					</div>
        <p class="big-text-p Gotham-Medium lantinghei">{{item.name}}</p>
        	<p class="time-p Gotham-Book lantingheijian">{{timestampToTime(item.publishTime)}}</p>
				<img class="left-img" :src="item.detailPictures[0]" alt="">
				<div class="right-text-box">
					<p class="txt-p Gotham-Book lantingheijian">
						{{item.contentText}}
					</p>
          <a class="more-btn" :href="item.buttonLinkUrl" v-if="item.buttonLinkUrl">
            <img src="../assets/img/more-btn-bg.png" alt="">
            <p class="txt Gotham-Book lantingheijian">{{item.buttonName}}</p>
          </a>
				</div>
			</div>
		</div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios'
import Header from "../components/Header"
import Footer from "../components/Footer"
import tezignSdk from '@tezignlab/tezign-external-track';
import clickPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/click';
import pageViewPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/page-view';
import instance from "../utils/http";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
       allMsg:[],
       twoNav:'',
       value:''
    }
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // this.cacheKey = query.cacheKey;
    this.getContent({"id": query.id})
    if(query.two){
        this.twoNav=query.two
    }
   if(query.value){
        this.value=query.value
    }
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
     updateHtmlMsg(){
        const token = localStorage.getItem("access-token");
          if(token){
            // 手动埋点
            var httpMsg=tezignSdk.track({
              event_type_code: 'Portal_Content_View',
              event_properties: {
                data_source: 'external_sdk',
                portal_id:this.allMsg[0].portalId?this.allMsg[0].portalId:0,
                block_id:this.allMsg[0].blockId?this.allMsg[0].blockId:0,
                content_id:this.allMsg[0].id?this.allMsg[0].id:0,
                asset_id:this.allMsg[0].coreId?this.allMsg[0].coreId:0,
              }
            })
            console.log(httpMsg)
          }
       },
       getBaseInfo2(data) {
          var $this = this;
          var data = {
            token: "",
            param: {
            blockId: interfaces.blockIdArr.skillBlockID,
            },
          };
          instance
            .post(`/open-api/standard/v1/getPublishSetting`, data, {
            headers: {
              "Content-Type": "application/json",
              "Token-type": "bearer",
            },
            })
            .then((res) => {
            
            if(res&&res.data.result){
              // 获取初始化数据
              $this.updateHtmlMsg()
            }else{
               $this.updateHtmlMsg()
            }
            
            });
        },
        //获取内容详情
        getContent(data){
          this.allMsg=[];
          var $this = this;
          console.log(this)
          var config = {
              method: "post",
              url: `${interfaces.baseUrl}/portal/open/block/getContent`,
              headers:interfaces.postHeaders(),
              data,
            };
            axios(config)
              .then(function (response) {
                  $this.allMsg.push(response.data.result);
                  console.log($this.allMsg)
                   $this.getBaseInfo2()
              })
              .catch(function (error) {
                  return error
              });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '年';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
            var D = date.getDate() + '日';
            return Y+M+D;
        },
        goAllMsg(){
          this.$router.push({
            name: 'newMessage',
          })
        },
         goYearsUpdate(){
          this.$router.push({
            name: 'yearsUpdate',
            query:{
              value:this.value
            }
          })
        },
  },
};
</script>
<style lang="less" scoped>
body{
	overflow: auto;
    background: #ffffff;
}
.news-main-box{
    width: 100%;
    min-height: calc(100vh - 4rem);
    background: #ffffff;
	// overflow: hidden;
  position: relative;
  padding-bottom: 1rem;
  box-sizing: border-box;
}
.news-main-box img{
	display: block;
	width: 100%;
}
.news-main-box .product01-box{
  width: 100%;
  min-height: calc(100vh);
  box-sizing: border-box;
  overflow: hidden;
	position: relative;
  background: #ffffff;
  margin-top: 1.3rem;
}
.news-main-box .product01-box .left-img{
    margin-top:0;
    // margin-left: 1.3rem;
    margin: 0 auto;
    width: 7rem;
    margin-top:.2rem;
	  // height: 17.6rem;
}
.news-main-box .product01-box .right-text-box{
  width: 6.9rem;
  margin: 0 auto;
}
.news-main-box .product01-box .big-text-p{
	color: #000000;
	font-size: .55rem;
	line-height:.75rem;
  width: 6.9rem;
  margin: 0 auto;
  margin-top: .2rem;
}
.news-main-box .product01-box .time-p{
	color:#000000;
	font-size: .36rem;
  margin-top: .3rem;
  width: 6.9rem;
  margin: 0 auto;
}
.news-main-box .product01-box .right-text-box .txt-p{
  color:#000000;
  margin: 0 auto;
  margin-top: .3rem;
	font-size: .36rem;
  // margin-top: .75rem;
  width: 6.9rem;
  white-space: pre-line;
}
.news-main-box .product01-box .right-text-box .more-btn{
  display: block;
  margin: 0 auto;
  margin-top:.8rem;
  width:6.9rem;
  height:.8rem;
  line-height:.8rem;
  font-size: .36rem;
  position: relative;
  text-align: center;
  color: #ffffff;
  // margin-left: -12px;
}
.news-main-box .product01-box .right-text-box .more-btn img{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.news-main-box .product01-box .right-text-box .more-btn .txt{
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom-two-nav{
  margin: 0 auto;
	// margin-top: .25rem;
  font-size:.18rem;
  width: 6.8rem;
  overflow: hidden;
}
.bottom-two-nav .p1{
	float: left;
	color:#c7c7c7;
}
.bottom-two-nav .shu{
	float: left;
	margin-left: .15rem;
}
.bottom-two-nav .p2{
	float: left;
  color:#c7c7c7;
  	margin-left: .15rem;
}
.bottom-two-nav .p3{
	float: left;
	margin-left: .15rem;
  color: #0d0c22;
  width:2.7rem;
  overflow:hidden; /*超出的部分隐藏起来。*/ 
	white-space:nowrap;/*不显示的地方用省略号...代替*/
	text-overflow:ellipsis;/* 支持 IE */
}
</style>